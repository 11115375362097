import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
};

export type AcceptDeclineRequestInput = {
  isAccept: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type AddPlayerEventInput = {
  eventId: Scalars['String'];
  isAdd: Scalars['Boolean'];
  playerId: Scalars['String'];
  userName: Scalars['String'];
};

export type AddRemoveFriendInput = {
  addFriend: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type AuthLoginInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  telegramAccessHash?: InputMaybe<Scalars['String']>;
  telegramId: Scalars['Float'];
  tokenVersion?: Scalars['Float'];
};

export type ContactInput = {
  contact: Scalars['String'];
  label: Scalars['String'];
};

export type ContactType = {
  __typename?: 'ContactType';
  contact: Scalars['String'];
  label: Scalars['String'];
};

export type CreateEventInput = {
  city: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  endTime?: InputMaybe<Scalars['String']>;
  fieldData?: InputMaybe<FieldInputType>;
  hobby: Scalars['String'];
  isIndoor: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  playerLimit?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['Date'];
  startTime: Scalars['String'];
  teamSize?: InputMaybe<Scalars['Float']>;
};

export type CreateHobbyInput = {
  groupId: Scalars['String'];
  title: Scalars['String'];
};

export type CreatePostInput = {
  chatId: Scalars['Float'];
  messageId: Scalars['Float'];
  text: Scalars['String'];
};

export type CreateTemplateInput = {
  hobbyTypeId: Scalars['String'];
  templateData: Scalars['String'];
};

export type EnrolledUser = {
  __typename?: 'EnrolledUser';
  playerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  fieldData?: Maybe<FieldType>;
  hobby: Scalars['String'];
  id: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isFull?: Maybe<Scalars['Boolean']>;
  isIndoor: Scalars['Boolean'];
  isInvited?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  joinedPlayer?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  messageId?: Maybe<Array<Message>>;
  ownerId: Scalars['String'];
  ownerName?: Maybe<Scalars['String']>;
  playerLimit?: Maybe<Scalars['Float']>;
  players?: Maybe<Array<User>>;
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  teamSize?: Maybe<Scalars['Float']>;
  userNetwork?: Maybe<Array<User>>;
};

export type EventInput = {
  city: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Date']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  fieldData?: InputMaybe<FieldInput>;
  hobby: Scalars['String'];
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isFull?: InputMaybe<Scalars['Boolean']>;
  isIndoor: Scalars['Boolean'];
  isInvited?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  joinedPlayer?: InputMaybe<Scalars['Float']>;
  lat?: InputMaybe<Scalars['String']>;
  long?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Array<MessageInput>>;
  ownerId: Scalars['String'];
  ownerName?: InputMaybe<Scalars['String']>;
  playerLimit?: InputMaybe<Scalars['Float']>;
  players?: InputMaybe<Array<UserInput>>;
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  teamSize?: InputMaybe<Scalars['Float']>;
  userNetwork?: InputMaybe<Array<UserInput>>;
};

export type EventPlayer = {
  __typename?: 'EventPlayer';
  addedBy?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  playerId?: Maybe<Scalars['String']>;
  positions: Array<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type EventPlayerChangeOutput = {
  __typename?: 'EventPlayerChangeOutput';
  deleted?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type EventTypeWithCount = {
  __typename?: 'EventTypeWithCount';
  result: Array<Event>;
  totalCount: Scalars['Float'];
};

export type FeatureFlagType = {
  __typename?: 'FeatureFlagType';
  isActive: Scalars['Boolean'];
};

export type FieldInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<ContactInput>>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  isIndoor?: InputMaybe<Scalars['Boolean']>;
  lat?: InputMaybe<Scalars['String']>;
  long?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type FieldInputType = {
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type FieldType = {
  __typename?: 'FieldType';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<ContactType>>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isIndoor?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type FollowUnfollowInput = {
  isFollow: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type GetEventByUserInput = {
  limit: Scalars['Float'];
  skip: Scalars['Float'];
  userId: Scalars['String'];
};

export type GetEventDetailInput = {
  id: Scalars['ID'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type GroupType = {
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Hobby = {
  __typename?: 'Hobby';
  addedBy?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type HobbyGroup = {
  __typename?: 'HobbyGroup';
  hobbies: Array<Hobby>;
  hobbyGroups: Array<Group>;
};

export type HobbyType = {
  addedBy?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InviteUninvitePlayersInput = {
  eventId: Scalars['String'];
  users: Array<Users>;
};

export type JoinEventInput = {
  eventId: Scalars['String'];
};

export type JoinEventType = {
  __typename?: 'JoinEventType';
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  playerId?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

export type LeaveEventInput = {
  eventId: Scalars['String'];
};

export type LeaveEventType = {
  __typename?: 'LeaveEventType';
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  playerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Links = {
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  chatId?: Maybe<Scalars['String']>;
  chatName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteLink?: Maybe<Scalars['String']>;
};

export type MessageInput = {
  chatId?: InputMaybe<Scalars['String']>;
  chatName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteLink?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptEventInvitation: Scalars['String'];
  addFriend: Scalars['String'];
  addPlayerToEvent: User;
  approveDeclineFriendsRequest: Scalars['String'];
  blockUser: Array<Event>;
  confirmField: FieldType;
  createEvent: Event;
  createField: FieldType;
  createHobby: Hobby;
  createPost: Post;
  createTemplateEvent: Template;
  declineEventInvitation: Scalars['String'];
  deleteUser: User;
  followUser: Scalars['String'];
  inviteUninvitePlayers: Scalars['String'];
  joinEvent: JoinEventType;
  leaveEvent: LeaveEventType;
  login: UserToken;
  logout: Scalars['Boolean'];
  markReadAllNotifications: Scalars['String'];
  newAccessToken: Scalars['String'];
  refreshTokenByAdmin: Scalars['String'];
  refreshUserTokenByAdmin: Scalars['String'];
  removeEvent: Scalars['String'];
  removeFriend: Scalars['String'];
  removePlayerToEvent: User;
  removePost: Post;
  removeUser: User;
  revokeUser: Scalars['Boolean'];
  toggleArchiveStatus: Event;
  toggleFeatureFlag: FeatureFlagType;
  toggleStatusEvent: Event;
  unFollowUser: Scalars['String'];
  updateEvent: Event;
  updatePosition: User;
  updatePost: Post;
  updateProfile: User;
  updateUser: User;
  updateUserFields: User;
};


export type MutationAcceptEventInvitationArgs = {
  eventId: Scalars['String'];
};


export type MutationAddFriendArgs = {
  addRemoveFriendInput: AddRemoveFriendInput;
};


export type MutationAddPlayerToEventArgs = {
  addPlayerEventInput: AddPlayerEventInput;
};


export type MutationApproveDeclineFriendsRequestArgs = {
  acceptDeclineRequestInput: AcceptDeclineRequestInput;
};


export type MutationBlockUserArgs = {
  blockedUntil: Scalars['Date'];
  telegramId: Scalars['Float'];
  userId: Scalars['String'];
};


export type MutationConfirmFieldArgs = {
  id: Scalars['String'];
};


export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
  isPostToTG: Scalars['Boolean'];
};


export type MutationCreateFieldArgs = {
  newField: NewFieldInput;
};


export type MutationCreateHobbyArgs = {
  newHobby: CreateHobbyInput;
};


export type MutationCreatePostArgs = {
  createPostInput: CreatePostInput;
};


export type MutationCreateTemplateEventArgs = {
  createTemplate: CreateTemplateInput;
};


export type MutationDeclineEventInvitationArgs = {
  eventId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationFollowUserArgs = {
  followUnfollowInput: FollowUnfollowInput;
};


export type MutationInviteUninvitePlayersArgs = {
  inviteUninvitePlayersInput: InviteUninvitePlayersInput;
};


export type MutationJoinEventArgs = {
  joinEventInput: JoinEventInput;
};


export type MutationLeaveEventArgs = {
  leaveEventInput: LeaveEventInput;
};


export type MutationLoginArgs = {
  authLoginInput: AuthLoginInput;
};


export type MutationRefreshUserTokenByAdminArgs = {
  userId: Scalars['String'];
};


export type MutationRemoveEventArgs = {
  eventId: Scalars['String'];
};


export type MutationRemoveFriendArgs = {
  addRemoveFriendInput: AddRemoveFriendInput;
};


export type MutationRemovePlayerToEventArgs = {
  addPlayerEventInput: AddPlayerEventInput;
};


export type MutationRemovePostArgs = {
  postId: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['String'];
};


export type MutationToggleArchiveStatusArgs = {
  eventId: Scalars['String'];
  isArchived: Scalars['Boolean'];
};


export type MutationToggleStatusEventArgs = {
  eventInput: EventInput;
  isFull: Scalars['Boolean'];
};


export type MutationUnFollowUserArgs = {
  followUnfollowInput: FollowUnfollowInput;
};


export type MutationUpdateEventArgs = {
  updateEventInput: UpdateEventInput;
};


export type MutationUpdatePositionArgs = {
  updatePositionInput: UpdatePositionInput;
};


export type MutationUpdatePostArgs = {
  updatePostInput: UpdatePostInput;
};


export type MutationUpdateProfileArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserFieldsArgs = {
  input: UpdateUserInput;
  userId: Scalars['String'];
};

export type NewFieldInput = {
  address?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contacts?: InputMaybe<Array<ContactInput>>;
  description?: InputMaybe<Scalars['String']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  isIndoor?: InputMaybe<Scalars['Boolean']>;
  lat?: InputMaybe<Scalars['String']>;
  long?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NotificationChangeOutput = {
  __typename?: 'NotificationChangeOutput';
  notification?: Maybe<NotificationType>;
  type?: Maybe<Scalars['String']>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  fieldData?: Maybe<FieldType>;
  firstName?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  hobby?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  isUserResponded?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notification_type?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  playerLimit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  teamSize?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PaginationInputType = {
  city?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Float']>;
  followedBy?: InputMaybe<Scalars['String']>;
  hobby?: InputMaybe<Scalars['String']>;
  isIndoor?: InputMaybe<Scalars['Boolean']>;
  isOutdoor?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  openToJoin?: InputMaybe<Scalars['Boolean']>;
  skip: Scalars['Float'];
  startDate?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['String']>;
  teamSize?: InputMaybe<Scalars['Float']>;
};

export type Post = {
  __typename?: 'Post';
  chatId: Scalars['Float'];
  count: Scalars['Float'];
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  idArray: Array<Scalars['String']>;
  messageId: Scalars['Float'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type Query = {
  __typename?: 'Query';
  checkIfCityChannelExists: Scalars['Boolean'];
  generateMetrics?: Maybe<Scalars['String']>;
  getAllEvents: EventTypeWithCount;
  getAllFields: Array<FieldType>;
  getAllFieldsByAdmin: Array<FieldType>;
  getAllHobbies: HobbyGroup;
  getAllNotifications: Array<NotificationType>;
  getAllPosts: Array<Post>;
  getAllUsersByAdmin: Array<User>;
  getEventDetailAuthorized: Event;
  getEventDetailUnauthorized: Event;
  getEventTemplate?: Maybe<Template>;
  getEventTemplateCount?: Maybe<Scalars['Float']>;
  getEventsByUserId: EventTypeWithCount;
  getFeatureFlag: FeatureFlagType;
  getFollowedByMe: Array<User>;
  getFollowersByUserId: Array<User>;
  getFollowingByUserId: Array<User>;
  getFriendsWithMe: Array<User>;
  getMyEvents: EventTypeWithCount;
  getMyFollowers: Array<User>;
  getMyFriends: UserFriendsType;
  getPlayerList: Array<EventPlayer>;
  getPost: Post;
  getProfile: User;
  getUserNetwork: Array<User>;
  getUserProfile: UserProfileType;
  getUsers: Array<User>;
  getUsersEventsByAdmin: Array<Event>;
};


export type QueryCheckIfCityChannelExistsArgs = {
  city: Scalars['String'];
};


export type QueryGenerateMetricsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetAllEventsArgs = {
  paginationInputType: PaginationInputType;
};


export type QueryGetAllFieldsArgs = {
  city: Scalars['String'];
};


export type QueryGetAllNotificationsArgs = {
  count?: InputMaybe<Scalars['Float']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  lastTimestamp?: InputMaybe<Scalars['Date']>;
};


export type QueryGetEventDetailAuthorizedArgs = {
  getEventDetailInput: GetEventDetailInput;
};


export type QueryGetEventDetailUnauthorizedArgs = {
  getEventDetailInput: GetEventDetailInput;
};


export type QueryGetEventsByUserIdArgs = {
  getEventByUserInput: GetEventByUserInput;
};


export type QueryGetFollowersByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetFollowingByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetMyEventsArgs = {
  paginationInputType: PaginationInputType;
};


export type QueryGetMyFriendsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetPlayerListArgs = {
  eventId: Scalars['String'];
};


export type QueryGetPostArgs = {
  id: Scalars['String'];
};


export type QueryGetProfileArgs = {
  id: Scalars['String'];
};


export type QueryGetUserProfileArgs = {
  id: Scalars['String'];
};


export type QueryGetUsersEventsByAdminArgs = {
  userId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  eventChanges: Event;
  eventDetailsChanges: Event;
  eventPlayerChanges: EventPlayerChangeOutput;
  notificationChanges: NotificationChangeOutput;
  postChanges: Post;
  userChanges: User;
};


export type SubscriptionEventDetailsChangesArgs = {
  filterEventInput: GetEventDetailInput;
};


export type SubscriptionEventPlayerChangesArgs = {
  eventId: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  createdAt: Scalars['Date'];
  hobbyTypeId: Scalars['String'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  ownerId: Scalars['String'];
  templateData?: Maybe<Scalars['String']>;
  templateOrder: Scalars['Float'];
};

export type UpdateEventInput = {
  city: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  endTime?: InputMaybe<Scalars['String']>;
  fieldData?: InputMaybe<FieldInputType>;
  hobby: Scalars['String'];
  id: Scalars['ID'];
  isFull: Scalars['Boolean'];
  isIndoor: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  messageId?: InputMaybe<Array<MessageInput>>;
  playerLimit?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['Date'];
  startTime: Scalars['String'];
  teamSize?: InputMaybe<Scalars['Float']>;
};

export type UpdatePositionInput = {
  positions?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdatePostInput = {
  chatId: Scalars['Float'];
  id: Scalars['ID'];
  idArray: Array<Scalars['ID']>;
  messageId: Scalars['Float'];
  text: Scalars['String'];
};

export type UpdateUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hobbies?: InputMaybe<Array<HobbyType>>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<Links>>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  positions?: InputMaybe<Array<Scalars['String']>>;
  role?: InputMaybe<Scalars['String']>;
  telegramId?: InputMaybe<Scalars['Float']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  addedBy?: Maybe<Scalars['String']>;
  addedByUser?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  blockedUntil?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enrolledUsers?: Maybe<Array<User>>;
  eventId?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  friendId?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Array<Hobby>>;
  id?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isCustomUser?: Maybe<Scalars['Boolean']>;
  lastActiveTime?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  links?: Maybe<Array<UserLinks>>;
  message?: Maybe<Scalars['String']>;
  newHobbies?: Maybe<Array<CreateHobby>>;
  nickName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  playerId?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Boolean']>;
  telegramAccessHash?: Maybe<Scalars['String']>;
  telegramId?: Maybe<Scalars['Float']>;
  tokenVersion: Scalars['Float'];
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserFriendsType = {
  __typename?: 'UserFriendsType';
  enrolledUsers?: Maybe<Array<EnrolledUser>>;
  friends?: Maybe<Array<User>>;
};

export type UserInput = {
  addedBy?: InputMaybe<Scalars['String']>;
  addedByUser?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  bio?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['Date']>;
  blockedUntil?: InputMaybe<Scalars['Date']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  enrolledUsers?: InputMaybe<Array<UserInput>>;
  eventId?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  friendId?: InputMaybe<Scalars['String']>;
  hobbies?: InputMaybe<Array<HobbyType>>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isCustomUser?: InputMaybe<Scalars['Boolean']>;
  lastActiveTime?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<UserLinksInput>>;
  message?: InputMaybe<Scalars['String']>;
  newHobbies?: InputMaybe<Array<CreateHobbyInput>>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  playerId?: InputMaybe<Scalars['String']>;
  positions?: InputMaybe<Array<Scalars['String']>>;
  role?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  telegramAccessHash?: InputMaybe<Scalars['String']>;
  telegramId?: InputMaybe<Scalars['Float']>;
  tokenVersion?: Scalars['Float'];
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

export type UserLinks = {
  __typename?: 'UserLinks';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UserLinksInput = {
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UserOutput = {
  __typename?: 'UserOutput';
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  telegramId?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserProfileType = {
  __typename?: 'UserProfileType';
  followers?: Maybe<Array<User>>;
  friends?: Maybe<Array<User>>;
  isOwner?: Maybe<Scalars['Boolean']>;
  totalEvent?: Maybe<Scalars['Float']>;
  userProfileData?: Maybe<User>;
};

export type UserToken = {
  __typename?: 'UserToken';
  token: Scalars['String'];
  user: UserOutput;
};

export type Users = {
  isInvite?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type CreateHobby = {
  __typename?: 'createHobby';
  groupId: Scalars['String'];
  title: Scalars['String'];
};

export type GetAllUsersByAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersByAdminQuery = { __typename?: 'Query', getAllUsersByAdmin: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, blockedUntil?: any | null, role?: string | null, telegramId?: number | null, photoUrl?: string | null, createdAt?: any | null, updatedAt?: any | null, isArchived?: boolean | null }> };

export type GetUsersEventsByAdminQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUsersEventsByAdminQuery = { __typename?: 'Query', getUsersEventsByAdmin: Array<{ __typename?: 'Event', id: string, startDate: string, startTime: string, endTime?: string | null, joinedPlayer?: number | null, teamSize?: number | null, isFull?: boolean | null, isIndoor: boolean, isPrivate: boolean, ownerId: string, playerLimit?: number | null, description: string, isArchived?: boolean | null, city: string, hobby: string, createdAt?: any | null, messageId?: Array<{ __typename?: 'Message', chatId?: string | null, chatName?: string | null, id?: string | null }> | null }> };

export type GenerateMetricsQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;


export type GenerateMetricsQuery = { __typename?: 'Query', generateMetrics?: string | null };

export type GetAllFieldsByAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllFieldsByAdminQuery = { __typename?: 'Query', getAllFieldsByAdmin: Array<{ __typename?: 'FieldType', address?: string | null, city?: string | null, description?: string | null, id?: string | null, isConfirmed?: boolean | null, isIndoor?: boolean | null, lat?: string | null, long?: string | null, title: string, contacts?: Array<{ __typename?: 'ContactType', contact: string, label: string }> | null }> };

export type BlockUserMutationVariables = Exact<{
  userId: Scalars['String'];
  blockedUntil: Scalars['Date'];
  telegramId: Scalars['Float'];
}>;


export type BlockUserMutation = { __typename?: 'Mutation', blockUser: Array<{ __typename?: 'Event', id: string, startDate: string, startTime: string, endTime?: string | null, joinedPlayer?: number | null, teamSize?: number | null, isFull?: boolean | null, playerLimit?: number | null, description: string, isArchived?: boolean | null, city: string, hobby: string, createdAt?: any | null, messageId?: Array<{ __typename?: 'Message', chatId?: string | null, chatName?: string | null, id?: string | null }> | null }> };

export type UpdateUserFieldsMutationVariables = Exact<{
  userId: Scalars['String'];
  updateFields: UpdateUserInput;
}>;


export type UpdateUserFieldsMutation = { __typename?: 'Mutation', updateUserFields: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, role?: string | null } };

export type ToggleArchiveStatusMutationVariables = Exact<{
  eventId: Scalars['String'];
  isArchived: Scalars['Boolean'];
}>;


export type ToggleArchiveStatusMutation = { __typename?: 'Mutation', toggleArchiveStatus: { __typename?: 'Event', id: string, isArchived?: boolean | null } };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id?: string | null, isArchived?: boolean | null } };

export type RefreshTokenByAdminMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenByAdminMutation = { __typename?: 'Mutation', refreshTokenByAdmin: string };

export type RefreshUserTokenByAdminMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type RefreshUserTokenByAdminMutation = { __typename?: 'Mutation', refreshUserTokenByAdmin: string };

export type ConfirmFieldMutationVariables = Exact<{
  confirmFieldId: Scalars['String'];
}>;


export type ConfirmFieldMutation = { __typename?: 'Mutation', confirmField: { __typename?: 'FieldType', id?: string | null, title: string, isConfirmed?: boolean | null } };

export type SigninMutationVariables = Exact<{
  loginInput: AuthLoginInput;
}>;


export type SigninMutation = { __typename?: 'Mutation', login: { __typename?: 'UserToken', token: string, user: { __typename?: 'UserOutput', id?: string | null, telegramId?: number | null, nickName?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null, role?: string | null } } };

export type GetCurrentUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCurrentUserQuery = { __typename?: 'Query', getProfile: { __typename?: 'User', id?: string | null, telegramId?: number | null, nickName?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null } };

export type NewAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type NewAccessTokenMutation = { __typename?: 'Mutation', newAccessToken: string };

export type GetFeatureFlagQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureFlagQuery = { __typename?: 'Query', getFeatureFlag: { __typename?: 'FeatureFlagType', isActive: boolean } };

export type ToggleFeatureFlagMutationVariables = Exact<{ [key: string]: never; }>;


export type ToggleFeatureFlagMutation = { __typename?: 'Mutation', toggleFeatureFlag: { __typename?: 'FeatureFlagType', isActive: boolean } };

export type GetEventTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventTemplateQuery = { __typename?: 'Query', getEventTemplate?: { __typename?: 'Template', templateData?: string | null } | null };

export type GetEventTemplateCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventTemplateCountQuery = { __typename?: 'Query', getEventTemplateCount?: number | null };

export type CreateTemplateEventMutationVariables = Exact<{
  createTemplate: CreateTemplateInput;
}>;


export type CreateTemplateEventMutation = { __typename?: 'Mutation', createTemplateEvent: { __typename?: 'Template', templateData?: string | null } };

export type GetAllEventsQueryVariables = Exact<{
  paginationInputType: PaginationInputType;
}>;


export type GetAllEventsQuery = { __typename?: 'Query', getAllEvents: { __typename?: 'EventTypeWithCount', totalCount: number, result: Array<{ __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, ownerId: string, joinedPlayer?: number | null, isArchived?: boolean | null, isPrivate: boolean, isOwner?: boolean | null, isFull?: boolean | null, createdAt?: any | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null }> } };

export type GetEventsByUserIdQueryVariables = Exact<{
  getEventByUserInput: GetEventByUserInput;
}>;


export type GetEventsByUserIdQuery = { __typename?: 'Query', getEventsByUserId: { __typename?: 'EventTypeWithCount', totalCount: number, result: Array<{ __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, ownerId: string, joinedPlayer?: number | null, isArchived?: boolean | null, isPrivate: boolean, isOwner?: boolean | null, isFull?: boolean | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null }> } };

export type GetMyEventsQueryVariables = Exact<{
  paginationInputType: PaginationInputType;
}>;


export type GetMyEventsQuery = { __typename?: 'Query', getMyEvents: { __typename?: 'EventTypeWithCount', totalCount: number, result: Array<{ __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, ownerId: string, lat?: string | null, long?: string | null, joinedPlayer?: number | null, isArchived?: boolean | null, isPrivate: boolean, isOwner?: boolean | null, isFull?: boolean | null, createdAt?: any | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null }> } };

export type GetEventDetailAuthorizedQueryVariables = Exact<{
  getEventDetailInput: GetEventDetailInput;
}>;


export type GetEventDetailAuthorizedQuery = { __typename?: 'Query', getEventDetailAuthorized: { __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, isPrivate: boolean, ownerId: string, lat?: string | null, long?: string | null, joinedPlayer?: number | null, ownerName?: string | null, isOwner?: boolean | null, isInvited?: boolean | null, isFull?: boolean | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null, players?: Array<{ __typename?: 'User', id?: string | null, eventId?: string | null, playerId?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, positions?: Array<string> | null, photoUrl?: string | null, telegramId?: number | null, isCustomUser?: boolean | null, addedByUser?: string | null }> | null, userNetwork?: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null, telegramId?: number | null }> | null, messageId?: Array<{ __typename?: 'Message', id?: string | null, chatId?: string | null, chatName?: string | null, inviteLink?: string | null }> | null } };

export type GetEventDetailUnauthorizedQueryVariables = Exact<{
  getEventDetailInput: GetEventDetailInput;
}>;


export type GetEventDetailUnauthorizedQuery = { __typename?: 'Query', getEventDetailUnauthorized: { __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, isPrivate: boolean, ownerId: string, lat?: string | null, long?: string | null, joinedPlayer?: number | null, ownerName?: string | null, isOwner?: boolean | null, isInvited?: boolean | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null, players?: Array<{ __typename?: 'User', id?: string | null, eventId?: string | null, playerId?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, positions?: Array<string> | null, photoUrl?: string | null, telegramId?: number | null, isCustomUser?: boolean | null, addedByUser?: string | null }> | null, userNetwork?: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null, telegramId?: number | null }> | null } };

export type CheckIfCityChannelExistsQueryVariables = Exact<{
  city: Scalars['String'];
}>;


export type CheckIfCityChannelExistsQuery = { __typename?: 'Query', checkIfCityChannelExists: boolean };

export type CreateEventMutationVariables = Exact<{
  createEventInput: CreateEventInput;
  isPostToTG: Scalars['Boolean'];
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, ownerId: string, joinedPlayer?: number | null, isArchived?: boolean | null, isPrivate: boolean, isOwner?: boolean | null, isFull?: boolean | null, createdAt?: any | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null, messageId?: Array<{ __typename?: 'Message', id?: string | null, chatId?: string | null, chatName?: string | null, inviteLink?: string | null }> | null } };

export type UpdateEventMutationVariables = Exact<{
  updateEventInput: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, isArchived?: boolean | null, isFull?: boolean | null, createdAt?: any | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null, messageId?: Array<{ __typename?: 'Message', id?: string | null, chatId?: string | null, chatName?: string | null, inviteLink?: string | null }> | null } };

export type RemoveEventMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type RemoveEventMutation = { __typename?: 'Mutation', removeEvent: string };

export type JoinEventMutationVariables = Exact<{
  joinEventInput: JoinEventInput;
}>;


export type JoinEventMutation = { __typename?: 'Mutation', joinEvent: { __typename?: 'JoinEventType', id?: string | null, eventId?: string | null, playerId?: string | null, message?: string | null, positions?: Array<string> | null, photoUrl?: string | null } };

export type LeaveEventMutationVariables = Exact<{
  leaveEventInput: LeaveEventInput;
}>;


export type LeaveEventMutation = { __typename?: 'Mutation', leaveEvent: { __typename?: 'LeaveEventType', id?: string | null, eventId?: string | null, playerId?: string | null, message?: string | null } };

export type AddPlayerToEventMutationVariables = Exact<{
  addPlayerEventInput: AddPlayerEventInput;
}>;


export type AddPlayerToEventMutation = { __typename?: 'Mutation', addPlayerToEvent: { __typename?: 'User', id?: string | null, message?: string | null, positions?: Array<string> | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, photoUrl?: string | null } };

export type RemovePlayerToEventMutationVariables = Exact<{
  addPlayerEventInput: AddPlayerEventInput;
}>;


export type RemovePlayerToEventMutation = { __typename?: 'Mutation', removePlayerToEvent: { __typename?: 'User', id?: string | null, message?: string | null, positions?: Array<string> | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, photoUrl?: string | null } };

export type InviteUninvitePlayersMutationVariables = Exact<{
  inviteUninvitePlayersInput: InviteUninvitePlayersInput;
}>;


export type InviteUninvitePlayersMutation = { __typename?: 'Mutation', inviteUninvitePlayers: string };

export type AcceptEventInvitationMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type AcceptEventInvitationMutation = { __typename?: 'Mutation', acceptEventInvitation: string };

export type DeclineEventInvitationMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type DeclineEventInvitationMutation = { __typename?: 'Mutation', declineEventInvitation: string };

export type ToggleStatusEventMutationVariables = Exact<{
  eventInput: EventInput;
  isFull: Scalars['Boolean'];
}>;


export type ToggleStatusEventMutation = { __typename?: 'Mutation', toggleStatusEvent: { __typename?: 'Event', messageId?: Array<{ __typename?: 'Message', id?: string | null, chatId?: string | null, chatName?: string | null, inviteLink?: string | null }> | null } };

export type EventChangesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EventChangesSubscription = { __typename?: 'Subscription', eventChanges: { __typename?: 'Event', id: string, city: string, description: string, hobby: string, startTime: string, startDate: string, endDate?: string | null, endTime?: string | null, teamSize?: number | null, playerLimit?: number | null, isIndoor: boolean, ownerId: string, joinedPlayer?: number | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null } };

export type EventPlayerChangesSubscriptionVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type EventPlayerChangesSubscription = { __typename?: 'Subscription', eventPlayerChanges: { __typename?: 'EventPlayerChangeOutput', eventId?: string | null, deleted?: boolean | null, user?: { __typename?: 'User', id?: string | null, positions?: Array<string> | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, addedByUser?: string | null, addedBy?: string | null, isCustomUser?: boolean | null, photoUrl?: string | null } | null } };

export type UserChangesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserChangesSubscription = { __typename?: 'Subscription', userChanges: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, positions?: Array<string> | null } };

export type GetAllFieldsQueryVariables = Exact<{
  city: Scalars['String'];
}>;


export type GetAllFieldsQuery = { __typename?: 'Query', getAllFields: Array<{ __typename?: 'FieldType', id?: string | null, title: string, city?: string | null, address?: string | null, description?: string | null, isIndoor?: boolean | null, lat?: string | null, long?: string | null, contacts?: Array<{ __typename?: 'ContactType', label: string, contact: string }> | null }> };

export type CreateFieldMutationVariables = Exact<{
  newField: NewFieldInput;
}>;


export type CreateFieldMutation = { __typename?: 'Mutation', createField: { __typename?: 'FieldType', id?: string | null, title: string, isIndoor?: boolean | null, lat?: string | null, long?: string | null, address?: string | null, city?: string | null, description?: string | null, contacts?: Array<{ __typename?: 'ContactType', contact: string, label: string }> | null } };

export type GetAllHobbiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllHobbiesQuery = { __typename?: 'Query', getAllHobbies: { __typename?: 'HobbyGroup', hobbies: Array<{ __typename?: 'Hobby', groupId?: string | null, id?: string | null, title?: string | null }>, hobbyGroups: Array<{ __typename?: 'Group', id: string, title: string }> } };

export type CreateHobbyMutationVariables = Exact<{
  newHobby: CreateHobbyInput;
}>;


export type CreateHobbyMutation = { __typename?: 'Mutation', createHobby: { __typename?: 'Hobby', id?: string | null, title?: string | null, groupId?: string | null } };

export type GetAllNotificationsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Float']>;
  lastTimestamp?: InputMaybe<Scalars['Date']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAllNotificationsQuery = { __typename?: 'Query', getAllNotifications: Array<{ __typename?: 'NotificationType', id?: string | null, userId?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null, hobby?: string | null, group?: string | null, notification_type?: string | null, description?: string | null, isRead?: boolean | null, ownerId?: string | null, message?: string | null, title?: string | null, startTime?: string | null, endTime?: string | null, startDate?: string | null, endDate?: string | null, playerLimit?: string | null, teamSize?: string | null, eventId?: string | null, isUserResponded?: boolean | null, createdAt?: any | null, fieldData?: { __typename?: 'FieldType', id?: string | null, title: string } | null }> };

export type MarkReadAllNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkReadAllNotificationsMutation = { __typename?: 'Mutation', markReadAllNotifications: string };

export type NotificationChangesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationChangesSubscription = { __typename?: 'Subscription', notificationChanges: { __typename?: 'NotificationChangeOutput', notification?: { __typename?: 'NotificationType', id?: string | null, userId?: string | null, firstName?: string | null, lastName?: string | null, notification_type?: string | null, description?: string | null, isRead?: boolean | null, ownerId?: string | null, message?: string | null, title?: string | null, startTime?: string | null, endTime?: string | null, playerLimit?: string | null, teamSize?: string | null, eventId?: string | null, isUserResponded?: boolean | null } | null } };

export type GetPlayerListQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetPlayerListQuery = { __typename?: 'Query', getPlayerList: Array<{ __typename?: 'EventPlayer', id?: string | null, eventId?: string | null, playerId?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, positions: Array<string>, photoUrl?: string | null, addedBy?: string | null }> };

export type UserListQueryVariables = Exact<{ [key: string]: never; }>;


export type UserListQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id?: string | null, telegramId?: number | null, nickName?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null }> };

export type GetUserProfileQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserProfileQuery = { __typename?: 'Query', getUserProfile: { __typename?: 'UserProfileType', totalEvent?: number | null, isOwner?: boolean | null, userProfileData?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, positions?: Array<string> | null, photoUrl?: string | null, bio?: string | null, facebookUrl?: string | null, instagramUrl?: string | null, twitterUrl?: string | null, phoneNumber?: string | null, city?: string | null, hobbies?: Array<{ __typename?: 'Hobby', id?: string | null, title?: string | null }> | null, links?: Array<{ __typename?: 'UserLinks', title?: string | null, url?: string | null }> | null } | null, friends?: Array<{ __typename?: 'User', id?: string | null }> | null, followers?: Array<{ __typename?: 'User', id?: string | null }> | null } };

export type GetUserNetworkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNetworkQuery = { __typename?: 'Query', getUserNetwork: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null }> };

export type GetMyFriendsQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetMyFriendsQuery = { __typename?: 'Query', getMyFriends: { __typename?: 'UserFriendsType', friends?: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, positions?: Array<string> | null, photoUrl?: string | null }> | null, enrolledUsers?: Array<{ __typename?: 'EnrolledUser', playerId?: string | null, status?: string | null }> | null } };

export type GetFollowedByMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFollowedByMeQuery = { __typename?: 'Query', getFollowedByMe: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null }> };

export type GetFriendsWithMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFriendsWithMeQuery = { __typename?: 'Query', getFriendsWithMe: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null, positions?: Array<string> | null }> };

export type GetMyFollowersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyFollowersQuery = { __typename?: 'Query', getMyFollowers: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, photoUrl?: string | null }> };

export type GetFollowersByUserIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetFollowersByUserIdQuery = { __typename?: 'Query', getFollowersByUserId: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, positions?: Array<string> | null, photoUrl?: string | null }> };

export type GetFollowingByUserIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetFollowingByUserIdQuery = { __typename?: 'Query', getFollowingByUserId: Array<{ __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, positions?: Array<string> | null, photoUrl?: string | null }> };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, nickName?: string | null, positions?: Array<string> | null, facebookUrl?: string | null, instagramUrl?: string | null, twitterUrl?: string | null, phoneNumber?: string | null, city?: string | null, hobbies?: Array<{ __typename?: 'Hobby', id?: string | null, title?: string | null, groupId?: string | null }> | null, links?: Array<{ __typename?: 'UserLinks', title?: string | null, url?: string | null }> | null } };

export type UpdatePositionMutationVariables = Exact<{
  updatePositionInput: UpdatePositionInput;
}>;


export type UpdatePositionMutation = { __typename?: 'Mutation', updatePosition: { __typename?: 'User', id?: string | null, positions?: Array<string> | null } };

export type AddFriendMutationVariables = Exact<{
  addRemoveFriendInput: AddRemoveFriendInput;
}>;


export type AddFriendMutation = { __typename?: 'Mutation', addFriend: string };

export type RemoveFriendMutationVariables = Exact<{
  addRemoveFriendInput: AddRemoveFriendInput;
}>;


export type RemoveFriendMutation = { __typename?: 'Mutation', removeFriend: string };

export type ApproveDeclineFriendsRequestMutationVariables = Exact<{
  acceptDeclineRequestInput: AcceptDeclineRequestInput;
}>;


export type ApproveDeclineFriendsRequestMutation = { __typename?: 'Mutation', approveDeclineFriendsRequest: string };

export type FollowUserMutationVariables = Exact<{
  followUnfollowInput: FollowUnfollowInput;
}>;


export type FollowUserMutation = { __typename?: 'Mutation', followUser: string };

export type UnFollowUserMutationVariables = Exact<{
  followUnfollowInput: FollowUnfollowInput;
}>;


export type UnFollowUserMutation = { __typename?: 'Mutation', unFollowUser: string };

export const GetAllUsersByAdminDocument = gql`
    query getAllUsersByAdmin {
  getAllUsersByAdmin {
    id
    firstName
    lastName
    nickName
    blockedUntil
    role
    telegramId
    photoUrl
    createdAt
    updatedAt
    isArchived
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUsersByAdminGQL extends Apollo.Query<GetAllUsersByAdminQuery, GetAllUsersByAdminQueryVariables> {
    document = GetAllUsersByAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersEventsByAdminDocument = gql`
    query getUsersEventsByAdmin($userId: String!) {
  getUsersEventsByAdmin(userId: $userId) {
    id
    startDate
    startTime
    endTime
    joinedPlayer
    teamSize
    isFull
    isIndoor
    isPrivate
    ownerId
    playerLimit
    description
    isArchived
    city
    hobby
    messageId {
      chatId
      chatName
      id
    }
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersEventsByAdminGQL extends Apollo.Query<GetUsersEventsByAdminQuery, GetUsersEventsByAdminQueryVariables> {
    document = GetUsersEventsByAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateMetricsDocument = gql`
    query generateMetrics($startDate: Date, $endDate: Date) {
  generateMetrics(startDate: $startDate, endDate: $endDate)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateMetricsGQL extends Apollo.Query<GenerateMetricsQuery, GenerateMetricsQueryVariables> {
    document = GenerateMetricsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllFieldsByAdminDocument = gql`
    query getAllFieldsByAdmin {
  getAllFieldsByAdmin {
    address
    city
    contacts {
      contact
      label
    }
    description
    id
    isConfirmed
    isIndoor
    lat
    long
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllFieldsByAdminGQL extends Apollo.Query<GetAllFieldsByAdminQuery, GetAllFieldsByAdminQueryVariables> {
    document = GetAllFieldsByAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlockUserDocument = gql`
    mutation blockUser($userId: String!, $blockedUntil: Date!, $telegramId: Float!) {
  blockUser(userId: $userId, blockedUntil: $blockedUntil, telegramId: $telegramId) {
    id
    startDate
    startTime
    endTime
    joinedPlayer
    teamSize
    isFull
    playerLimit
    description
    isArchived
    city
    hobby
    createdAt
    messageId {
      chatId
      chatName
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockUserGQL extends Apollo.Mutation<BlockUserMutation, BlockUserMutationVariables> {
    document = BlockUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserFieldsDocument = gql`
    mutation updateUserFields($userId: String!, $updateFields: UpdateUserInput!) {
  updateUserFields(userId: $userId, input: $updateFields) {
    id
    firstName
    lastName
    nickName
    role
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserFieldsGQL extends Apollo.Mutation<UpdateUserFieldsMutation, UpdateUserFieldsMutationVariables> {
    document = UpdateUserFieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleArchiveStatusDocument = gql`
    mutation toggleArchiveStatus($eventId: String!, $isArchived: Boolean!) {
  toggleArchiveStatus(eventId: $eventId, isArchived: $isArchived) {
    id
    isArchived
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleArchiveStatusGQL extends Apollo.Mutation<ToggleArchiveStatusMutation, ToggleArchiveStatusMutationVariables> {
    document = ToggleArchiveStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: String!) {
  deleteUser(userId: $userId) {
    id
    isArchived
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RefreshTokenByAdminDocument = gql`
    mutation refreshTokenByAdmin {
  refreshTokenByAdmin
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshTokenByAdminGQL extends Apollo.Mutation<RefreshTokenByAdminMutation, RefreshTokenByAdminMutationVariables> {
    document = RefreshTokenByAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RefreshUserTokenByAdminDocument = gql`
    mutation refreshUserTokenByAdmin($userId: String!) {
  refreshUserTokenByAdmin(userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshUserTokenByAdminGQL extends Apollo.Mutation<RefreshUserTokenByAdminMutation, RefreshUserTokenByAdminMutationVariables> {
    document = RefreshUserTokenByAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmFieldDocument = gql`
    mutation confirmField($confirmFieldId: String!) {
  confirmField(id: $confirmFieldId) {
    id
    title
    isConfirmed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmFieldGQL extends Apollo.Mutation<ConfirmFieldMutation, ConfirmFieldMutationVariables> {
    document = ConfirmFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SigninDocument = gql`
    mutation signin($loginInput: AuthLoginInput!) {
  login(authLoginInput: $loginInput) {
    token
    user {
      id
      telegramId
      nickName
      firstName
      lastName
      photoUrl
      role
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SigninGQL extends Apollo.Mutation<SigninMutation, SigninMutationVariables> {
    document = SigninDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrentUserDocument = gql`
    query getCurrentUser($id: String!) {
  getProfile(id: $id) {
    id
    telegramId
    nickName
    firstName
    lastName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentUserGQL extends Apollo.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> {
    document = GetCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewAccessTokenDocument = gql`
    mutation newAccessToken {
  newAccessToken
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewAccessTokenGQL extends Apollo.Mutation<NewAccessTokenMutation, NewAccessTokenMutationVariables> {
    document = NewAccessTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFeatureFlagDocument = gql`
    query getFeatureFlag {
  getFeatureFlag {
    isActive
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFeatureFlagGQL extends Apollo.Query<GetFeatureFlagQuery, GetFeatureFlagQueryVariables> {
    document = GetFeatureFlagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleFeatureFlagDocument = gql`
    mutation toggleFeatureFlag {
  toggleFeatureFlag {
    isActive
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleFeatureFlagGQL extends Apollo.Mutation<ToggleFeatureFlagMutation, ToggleFeatureFlagMutationVariables> {
    document = ToggleFeatureFlagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEventTemplateDocument = gql`
    query getEventTemplate {
  getEventTemplate {
    templateData
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventTemplateGQL extends Apollo.Query<GetEventTemplateQuery, GetEventTemplateQueryVariables> {
    document = GetEventTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEventTemplateCountDocument = gql`
    query getEventTemplateCount {
  getEventTemplateCount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventTemplateCountGQL extends Apollo.Query<GetEventTemplateCountQuery, GetEventTemplateCountQueryVariables> {
    document = GetEventTemplateCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateEventDocument = gql`
    mutation createTemplateEvent($createTemplate: CreateTemplateInput!) {
  createTemplateEvent(createTemplate: $createTemplate) {
    templateData
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateEventGQL extends Apollo.Mutation<CreateTemplateEventMutation, CreateTemplateEventMutationVariables> {
    document = CreateTemplateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllEventsDocument = gql`
    query getAllEvents($paginationInputType: PaginationInputType!) {
  getAllEvents(paginationInputType: $paginationInputType) {
    totalCount
    result {
      id
      city
      description
      fieldData {
        id
        title
      }
      hobby
      startTime
      startDate
      endDate
      endTime
      teamSize
      playerLimit
      isIndoor
      ownerId
      joinedPlayer
      isArchived
      isPrivate
      isOwner
      isFull
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllEventsGQL extends Apollo.Query<GetAllEventsQuery, GetAllEventsQueryVariables> {
    document = GetAllEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEventsByUserIdDocument = gql`
    query getEventsByUserId($getEventByUserInput: GetEventByUserInput!) {
  getEventsByUserId(getEventByUserInput: $getEventByUserInput) {
    totalCount
    result {
      id
      city
      description
      fieldData {
        id
        title
      }
      hobby
      startTime
      startDate
      endDate
      endTime
      teamSize
      playerLimit
      isIndoor
      ownerId
      joinedPlayer
      isArchived
      isPrivate
      isOwner
      isFull
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventsByUserIdGQL extends Apollo.Query<GetEventsByUserIdQuery, GetEventsByUserIdQueryVariables> {
    document = GetEventsByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyEventsDocument = gql`
    query getMyEvents($paginationInputType: PaginationInputType!) {
  getMyEvents(paginationInputType: $paginationInputType) {
    totalCount
    result {
      id
      city
      description
      fieldData {
        id
        title
      }
      hobby
      startTime
      startDate
      endDate
      endTime
      teamSize
      playerLimit
      isIndoor
      ownerId
      lat
      long
      joinedPlayer
      isArchived
      isPrivate
      isOwner
      isFull
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyEventsGQL extends Apollo.Query<GetMyEventsQuery, GetMyEventsQueryVariables> {
    document = GetMyEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEventDetailAuthorizedDocument = gql`
    query getEventDetailAuthorized($getEventDetailInput: GetEventDetailInput!) {
  getEventDetailAuthorized(getEventDetailInput: $getEventDetailInput) {
    id
    city
    description
    fieldData {
      id
      title
    }
    hobby
    startTime
    startDate
    endDate
    endTime
    teamSize
    playerLimit
    isIndoor
    isPrivate
    ownerId
    lat
    long
    joinedPlayer
    ownerName
    isOwner
    isInvited
    players {
      id
      eventId
      playerId
      firstName
      lastName
      nickName
      positions
      photoUrl
      telegramId
      isCustomUser
      addedByUser
    }
    userNetwork {
      id
      firstName
      lastName
      photoUrl
      telegramId
    }
    messageId {
      id
      chatId
      chatName
      inviteLink
    }
    isFull
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventDetailAuthorizedGQL extends Apollo.Query<GetEventDetailAuthorizedQuery, GetEventDetailAuthorizedQueryVariables> {
    document = GetEventDetailAuthorizedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEventDetailUnauthorizedDocument = gql`
    query getEventDetailUnauthorized($getEventDetailInput: GetEventDetailInput!) {
  getEventDetailUnauthorized(getEventDetailInput: $getEventDetailInput) {
    id
    city
    description
    fieldData {
      id
      title
    }
    hobby
    startTime
    startDate
    endDate
    endTime
    teamSize
    playerLimit
    isIndoor
    isPrivate
    ownerId
    lat
    long
    joinedPlayer
    ownerName
    isOwner
    isInvited
    players {
      id
      eventId
      playerId
      firstName
      lastName
      nickName
      positions
      photoUrl
      telegramId
      isCustomUser
      addedByUser
    }
    userNetwork {
      id
      firstName
      lastName
      photoUrl
      telegramId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventDetailUnauthorizedGQL extends Apollo.Query<GetEventDetailUnauthorizedQuery, GetEventDetailUnauthorizedQueryVariables> {
    document = GetEventDetailUnauthorizedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckIfCityChannelExistsDocument = gql`
    query checkIfCityChannelExists($city: String!) {
  checkIfCityChannelExists(city: $city)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckIfCityChannelExistsGQL extends Apollo.Query<CheckIfCityChannelExistsQuery, CheckIfCityChannelExistsQueryVariables> {
    document = CheckIfCityChannelExistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEventDocument = gql`
    mutation createEvent($createEventInput: CreateEventInput!, $isPostToTG: Boolean!) {
  createEvent(createEventInput: $createEventInput, isPostToTG: $isPostToTG) {
    id
    city
    description
    fieldData {
      id
      title
    }
    hobby
    startTime
    startDate
    endDate
    endTime
    teamSize
    playerLimit
    isIndoor
    ownerId
    joinedPlayer
    isArchived
    isPrivate
    isOwner
    isFull
    createdAt
    messageId {
      id
      chatId
      chatName
      inviteLink
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEventGQL extends Apollo.Mutation<CreateEventMutation, CreateEventMutationVariables> {
    document = CreateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventDocument = gql`
    mutation updateEvent($updateEventInput: UpdateEventInput!) {
  updateEvent(updateEventInput: $updateEventInput) {
    id
    city
    description
    fieldData {
      id
      title
    }
    hobby
    startTime
    startDate
    endDate
    endTime
    teamSize
    playerLimit
    isIndoor
    isArchived
    isFull
    createdAt
    messageId {
      id
      chatId
      chatName
      inviteLink
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventGQL extends Apollo.Mutation<UpdateEventMutation, UpdateEventMutationVariables> {
    document = UpdateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveEventDocument = gql`
    mutation removeEvent($eventId: String!) {
  removeEvent(eventId: $eventId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveEventGQL extends Apollo.Mutation<RemoveEventMutation, RemoveEventMutationVariables> {
    document = RemoveEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JoinEventDocument = gql`
    mutation joinEvent($joinEventInput: JoinEventInput!) {
  joinEvent(joinEventInput: $joinEventInput) {
    id
    eventId
    playerId
    message
    positions
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JoinEventGQL extends Apollo.Mutation<JoinEventMutation, JoinEventMutationVariables> {
    document = JoinEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LeaveEventDocument = gql`
    mutation leaveEvent($leaveEventInput: LeaveEventInput!) {
  leaveEvent(leaveEventInput: $leaveEventInput) {
    id
    eventId
    playerId
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LeaveEventGQL extends Apollo.Mutation<LeaveEventMutation, LeaveEventMutationVariables> {
    document = LeaveEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddPlayerToEventDocument = gql`
    mutation addPlayerToEvent($addPlayerEventInput: AddPlayerEventInput!) {
  addPlayerToEvent(addPlayerEventInput: $addPlayerEventInput) {
    id
    message
    positions
    firstName
    lastName
    nickName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPlayerToEventGQL extends Apollo.Mutation<AddPlayerToEventMutation, AddPlayerToEventMutationVariables> {
    document = AddPlayerToEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemovePlayerToEventDocument = gql`
    mutation removePlayerToEvent($addPlayerEventInput: AddPlayerEventInput!) {
  removePlayerToEvent(addPlayerEventInput: $addPlayerEventInput) {
    id
    message
    positions
    firstName
    lastName
    nickName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePlayerToEventGQL extends Apollo.Mutation<RemovePlayerToEventMutation, RemovePlayerToEventMutationVariables> {
    document = RemovePlayerToEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteUninvitePlayersDocument = gql`
    mutation inviteUninvitePlayers($inviteUninvitePlayersInput: InviteUninvitePlayersInput!) {
  inviteUninvitePlayers(inviteUninvitePlayersInput: $inviteUninvitePlayersInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteUninvitePlayersGQL extends Apollo.Mutation<InviteUninvitePlayersMutation, InviteUninvitePlayersMutationVariables> {
    document = InviteUninvitePlayersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptEventInvitationDocument = gql`
    mutation acceptEventInvitation($eventId: String!) {
  acceptEventInvitation(eventId: $eventId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptEventInvitationGQL extends Apollo.Mutation<AcceptEventInvitationMutation, AcceptEventInvitationMutationVariables> {
    document = AcceptEventInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeclineEventInvitationDocument = gql`
    mutation declineEventInvitation($eventId: String!) {
  declineEventInvitation(eventId: $eventId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeclineEventInvitationGQL extends Apollo.Mutation<DeclineEventInvitationMutation, DeclineEventInvitationMutationVariables> {
    document = DeclineEventInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleStatusEventDocument = gql`
    mutation toggleStatusEvent($eventInput: EventInput!, $isFull: Boolean!) {
  toggleStatusEvent(eventInput: $eventInput, isFull: $isFull) {
    messageId {
      id
      chatId
      chatName
      inviteLink
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleStatusEventGQL extends Apollo.Mutation<ToggleStatusEventMutation, ToggleStatusEventMutationVariables> {
    document = ToggleStatusEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventChangesDocument = gql`
    subscription eventChanges {
  eventChanges {
    id
    city
    description
    fieldData {
      id
      title
    }
    hobby
    startTime
    startDate
    endDate
    endTime
    teamSize
    playerLimit
    isIndoor
    ownerId
    joinedPlayer
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventChangesGQL extends Apollo.Subscription<EventChangesSubscription, EventChangesSubscriptionVariables> {
    document = EventChangesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventPlayerChangesDocument = gql`
    subscription eventPlayerChanges($eventId: String!) {
  eventPlayerChanges(eventId: $eventId) {
    eventId
    user {
      id
      positions
      firstName
      lastName
      nickName
      addedByUser
      addedBy
      isCustomUser
      photoUrl
    }
    deleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventPlayerChangesGQL extends Apollo.Subscription<EventPlayerChangesSubscription, EventPlayerChangesSubscriptionVariables> {
    document = EventPlayerChangesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserChangesDocument = gql`
    subscription userChanges {
  userChanges {
    id
    firstName
    lastName
    positions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserChangesGQL extends Apollo.Subscription<UserChangesSubscription, UserChangesSubscriptionVariables> {
    document = UserChangesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllFieldsDocument = gql`
    query getAllFields($city: String!) {
  getAllFields(city: $city) {
    id
    title
    city
    address
    contacts {
      label
      contact
    }
    description
    isIndoor
    lat
    long
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllFieldsGQL extends Apollo.Query<GetAllFieldsQuery, GetAllFieldsQueryVariables> {
    document = GetAllFieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFieldDocument = gql`
    mutation createField($newField: NewFieldInput!) {
  createField(newField: $newField) {
    id
    title
    isIndoor
    lat
    long
    address
    city
    contacts {
      contact
      label
    }
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFieldGQL extends Apollo.Mutation<CreateFieldMutation, CreateFieldMutationVariables> {
    document = CreateFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllHobbiesDocument = gql`
    query getAllHobbies {
  getAllHobbies {
    hobbies {
      groupId
      id
      title
    }
    hobbyGroups {
      id
      title
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllHobbiesGQL extends Apollo.Query<GetAllHobbiesQuery, GetAllHobbiesQueryVariables> {
    document = GetAllHobbiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHobbyDocument = gql`
    mutation createHobby($newHobby: CreateHobbyInput!) {
  createHobby(newHobby: $newHobby) {
    id
    title
    groupId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHobbyGQL extends Apollo.Mutation<CreateHobbyMutation, CreateHobbyMutationVariables> {
    document = CreateHobbyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllNotificationsDocument = gql`
    query getAllNotifications($count: Float, $lastTimestamp: Date, $isRead: Boolean) {
  getAllNotifications(
    count: $count
    lastTimestamp: $lastTimestamp
    isRead: $isRead
  ) {
    id
    userId
    firstName
    lastName
    photoUrl
    fieldData {
      id
      title
    }
    hobby
    group
    notification_type
    description
    isRead
    ownerId
    message
    title
    startTime
    endTime
    startDate
    endDate
    playerLimit
    teamSize
    eventId
    isUserResponded
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllNotificationsGQL extends Apollo.Query<GetAllNotificationsQuery, GetAllNotificationsQueryVariables> {
    document = GetAllNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkReadAllNotificationsDocument = gql`
    mutation markReadAllNotifications {
  markReadAllNotifications
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkReadAllNotificationsGQL extends Apollo.Mutation<MarkReadAllNotificationsMutation, MarkReadAllNotificationsMutationVariables> {
    document = MarkReadAllNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationChangesDocument = gql`
    subscription notificationChanges {
  notificationChanges {
    notification {
      id
      userId
      firstName
      lastName
      notification_type
      description
      isRead
      ownerId
      message
      title
      startTime
      endTime
      playerLimit
      teamSize
      eventId
      isUserResponded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationChangesGQL extends Apollo.Subscription<NotificationChangesSubscription, NotificationChangesSubscriptionVariables> {
    document = NotificationChangesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPlayerListDocument = gql`
    query getPlayerList($eventId: String!) {
  getPlayerList(eventId: $eventId) {
    id
    eventId
    playerId
    firstName
    lastName
    nickName
    positions
    photoUrl
    addedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPlayerListGQL extends Apollo.Query<GetPlayerListQuery, GetPlayerListQueryVariables> {
    document = GetPlayerListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserListDocument = gql`
    query userList {
  getUsers {
    id
    telegramId
    nickName
    firstName
    lastName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserListGQL extends Apollo.Query<UserListQuery, UserListQueryVariables> {
    document = UserListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserProfileDocument = gql`
    query getUserProfile($id: String!) {
  getUserProfile(id: $id) {
    userProfileData {
      id
      firstName
      lastName
      nickName
      positions
      photoUrl
      hobbies {
        id
        title
      }
      bio
      links {
        title
        url
      }
      facebookUrl
      instagramUrl
      twitterUrl
      phoneNumber
      city
    }
    totalEvent
    isOwner
    friends {
      id
    }
    followers {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserProfileGQL extends Apollo.Query<GetUserProfileQuery, GetUserProfileQueryVariables> {
    document = GetUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserNetworkDocument = gql`
    query getUserNetwork {
  getUserNetwork {
    id
    firstName
    lastName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserNetworkGQL extends Apollo.Query<GetUserNetworkQuery, GetUserNetworkQueryVariables> {
    document = GetUserNetworkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyFriendsDocument = gql`
    query getMyFriends($eventId: String!) {
  getMyFriends(eventId: $eventId) {
    friends {
      id
      firstName
      lastName
      positions
      photoUrl
    }
    enrolledUsers {
      playerId
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyFriendsGQL extends Apollo.Query<GetMyFriendsQuery, GetMyFriendsQueryVariables> {
    document = GetMyFriendsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFollowedByMeDocument = gql`
    query getFollowedByMe {
  getFollowedByMe {
    id
    firstName
    lastName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFollowedByMeGQL extends Apollo.Query<GetFollowedByMeQuery, GetFollowedByMeQueryVariables> {
    document = GetFollowedByMeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFriendsWithMeDocument = gql`
    query getFriendsWithMe {
  getFriendsWithMe {
    id
    firstName
    lastName
    photoUrl
    positions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFriendsWithMeGQL extends Apollo.Query<GetFriendsWithMeQuery, GetFriendsWithMeQueryVariables> {
    document = GetFriendsWithMeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyFollowersDocument = gql`
    query getMyFollowers {
  getMyFollowers {
    id
    firstName
    lastName
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyFollowersGQL extends Apollo.Query<GetMyFollowersQuery, GetMyFollowersQueryVariables> {
    document = GetMyFollowersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFollowersByUserIdDocument = gql`
    query getFollowersByUserId($userId: String!) {
  getFollowersByUserId(userId: $userId) {
    id
    firstName
    lastName
    positions
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFollowersByUserIdGQL extends Apollo.Query<GetFollowersByUserIdQuery, GetFollowersByUserIdQueryVariables> {
    document = GetFollowersByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFollowingByUserIdDocument = gql`
    query getFollowingByUserId($userId: String!) {
  getFollowingByUserId(userId: $userId) {
    id
    firstName
    lastName
    positions
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFollowingByUserIdGQL extends Apollo.Query<GetFollowingByUserIdQuery, GetFollowingByUserIdQueryVariables> {
    document = GetFollowingByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    id
    firstName
    lastName
    nickName
    positions
    hobbies {
      id
      title
      groupId
    }
    links {
      title
      url
    }
    facebookUrl
    instagramUrl
    twitterUrl
    phoneNumber
    city
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePositionDocument = gql`
    mutation updatePosition($updatePositionInput: UpdatePositionInput!) {
  updatePosition(updatePositionInput: $updatePositionInput) {
    id
    positions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePositionGQL extends Apollo.Mutation<UpdatePositionMutation, UpdatePositionMutationVariables> {
    document = UpdatePositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddFriendDocument = gql`
    mutation addFriend($addRemoveFriendInput: AddRemoveFriendInput!) {
  addFriend(addRemoveFriendInput: $addRemoveFriendInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddFriendGQL extends Apollo.Mutation<AddFriendMutation, AddFriendMutationVariables> {
    document = AddFriendDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveFriendDocument = gql`
    mutation removeFriend($addRemoveFriendInput: AddRemoveFriendInput!) {
  removeFriend(addRemoveFriendInput: $addRemoveFriendInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveFriendGQL extends Apollo.Mutation<RemoveFriendMutation, RemoveFriendMutationVariables> {
    document = RemoveFriendDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApproveDeclineFriendsRequestDocument = gql`
    mutation approveDeclineFriendsRequest($acceptDeclineRequestInput: AcceptDeclineRequestInput!) {
  approveDeclineFriendsRequest(
    acceptDeclineRequestInput: $acceptDeclineRequestInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApproveDeclineFriendsRequestGQL extends Apollo.Mutation<ApproveDeclineFriendsRequestMutation, ApproveDeclineFriendsRequestMutationVariables> {
    document = ApproveDeclineFriendsRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FollowUserDocument = gql`
    mutation followUser($followUnfollowInput: FollowUnfollowInput!) {
  followUser(followUnfollowInput: $followUnfollowInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FollowUserGQL extends Apollo.Mutation<FollowUserMutation, FollowUserMutationVariables> {
    document = FollowUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnFollowUserDocument = gql`
    mutation unFollowUser($followUnfollowInput: FollowUnfollowInput!) {
  unFollowUser(followUnfollowInput: $followUnfollowInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnFollowUserGQL extends Apollo.Mutation<UnFollowUserMutation, UnFollowUserMutationVariables> {
    document = UnFollowUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }